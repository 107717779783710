<template>
  <div class="contents-section">
    <div class="title-section">
      <h2>알림센터</h2>
      <!-- <v-btn-toggle
        v-model="type"
        mandatory
        dense
      >
        <v-btn :value="0">
          받은 알림
        </v-btn>
        <v-btn :value="1">
          보낸 알림
        </v-btn>
      </v-btn-toggle> -->
    </div>
    <div class="main-section">
      <search-filter
        :dateFilter="dateFilter"
        :radioFilters="radioFilters"
        :search="search"
        type="server"
        searchPlaceHolder="발신인으로 검색"
        @updateRadio="updateRadio"
        @updateSearch="updateSearch"
      />
      <v-row
        justify="space-between"
        align="center"
        class="mr-0 ml-0 mt-0 mb-0"
      >
        <v-col
        >
          <v-radio-group
            @change="options.itemsPerPage = $event"
            dense
            row
            :value="options.itemsPerPage"
            hide-details
          >
            <v-radio
              small
              label="50개씩 보기"
              :value="50"
            ></v-radio>
            <v-radio
              small
              label="100개씩 보기"
              :value="100"
            ></v-radio>
            <v-radio
              small
              label="500개씩 보기"
              :value="500"
            ></v-radio>
            <v-radio
              small
              label="1000개씩 보기"
              :value="1000"
            ></v-radio>
            <v-radio
              small
              label="3000개씩 보기"
              :value="3000"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-btn
          small
          class="mt-6"
          depressed
          color="primary"
          @click="updateNotification(selectRow)"
          :disabled="selectRow.filter((r) => r.read === false).length === 0"
        >일괄읽음처리({{ selectRow.filter((r) => r.read === false).length }})</v-btn>
      </v-row>
      <v-data-table
        show-select
        selectable-key="no"
        :headers="headers"
        :items="lists"
        item-key="no"
        :options="options"
        :loading="loading"
        class="elevation-0"
        dense
        checkbox-color="primary"
        :server-items-length="totalItems"
        hide-default-footer
        @update:sort-by="updateOptions('sortBy', $event)"
        @update:sort-desc="updateOptions('sortDesc', $event)"
        @input="selectRow = $event"
      >
        <template #[`item.type`]="{ item }">
          <v-chip
            small
            outlined
            label
          >
            {{ comm.transNotifictionType(item.type) }}
          </v-chip>
        </template>
        <template #[`item.read`]="{ item }">
          <v-chip
            v-if="item.read === false"
            small
            color="error"
          >
            미확인
          </v-chip>
          <v-chip
            v-else-if="item.read === true"
            small
          >
            확인
          </v-chip>
        </template>
        <template #[`item.created`]="{ item }">
          {{ time.makeLocalTime(item.created, 'min') }}
        </template>
        <template #[`item.sender`]="{ item }">
          <v-chip
            class="pl-1 pr-1"
            small label outlined
          >{{ comm.getGroupFullPath(groups, item.senderGroupNo, '그룹없음') }}</v-chip>
          {{ item.senderName }}
        </template>
        <template #[`item.redirect`]="{ item }">
          <v-btn
            v-if="item.redirect"
            icon
            @click="enterNotification({ no: item.no, redirect: item.redirect })"
          >
            <v-icon color="grey lighten-1">mdi-arrow-right-circle</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <div class="text-center pt-4 d-flex justify-center align-center">
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = 1"
          :disabled="options.page === 1"
        >
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-pagination
          v-if="options.itemsPerPage > 0"
          small
          color="primary"
          v-model="options.page"
          :length="Math.ceil(totalItems / options.itemsPerPage)"
          totalVisible="15"
          depressed
        ></v-pagination>
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = Math.ceil(totalItems / options.itemsPerPage)"
          :disabled="options.page === Math.ceil(totalItems / options.itemsPerPage)"
        >
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import SearchFilter from '@/components/layout/SearchFilter.vue';
import time from '@/util/time';
import comm from '@/util/comm';

export default {
  name: 'Notification',
  components: {
    SearchFilter,
  },
  data: () => ({
    selectRow: [],
    type: 0,
    comm,
    time,
    groups: [],
    loading: true,
    dateFilter: {
      label: '수신일',
      startDate: time.moment().add('-1', 'Y').format('YYYY-MM-DD'),
      startPicker: false,
      endDate: time.moment().format('YYYY-MM-DD'),
      endPicker: false,
    },
    lists: [],
    radioFilters: {
      read: {
        key: 'read',
        name: '상태',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '미확인', value: false },
          { name: '확인', value: true },
        ],
      },
    },
    search: '',
    totalItems: 0,
    options: {
      page: 1,
      itemsPerPage: 50,
      sortBy: ['created'],
      sortDesc: [true],
      filters: [
        { condition: 'ge', column: 'created', value: time.makeUTCDate(time.moment().add('-1', 'Y').format('YYYY-MM-DD')) },
        {
          where: 'and',
          condition: 'le',
          column: 'created',
          value: `
            ${time.makeUTCDate(time.moment().format('YYYY-MM-DD'))} 23:59:59
          `,
        },
        {
          where: 'and',
          condition: 'inc',
          column: 'read',
          value: '',
        },
        {
          where: 'and',
          filters: [
            {
              condition: 'inc',
              column: 'senderName',
              value: '',
            },
          ],
        },
      ],
    },
  }),
  computed: {

    headers() {
      return [
        {
          text: '상태',
          value: 'read',
          align: 'center',
        },
        {
          text: '종류',
          value: 'type',
          align: 'center',
        },
        {
          text: '발신인',
          value: 'sender',
        },
        {
          text: '내용',
          value: 'content',
        },
        { text: '수신일시', value: 'created' },
        { text: '바로가기', value: 'redirect', sortable: false },
      ];
    },
  },
  methods: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
    ...mapActions({
      enterNotification: 'notification/enterNotification',
      transNotifictionType: 'notification/transNotifictionType',
      alert: 'dialog/alert',
    }),
    ...mapMutations({
      progress: 'dialog/progress',
      notifications: 'notification/notifications',
      notificationModal: 'notification/notificationModal',
    }),
    /* 라디오필터 업데이트 */
    updateRadio(value, key) {
      this.radioFilters[key].value = value;
    },
    /* 검색 업데이트 */
    updateSearch(value) {
      this.search = value;
    },
    getSummary() {
      this.$socket.emit('notification.summary.get', {
        filters: this.options.filters,
      }, (resp) => {
        if (resp.result === 'success') {
          this.totalItems = resp.item[0].count;
          this.getList();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    getList() {
      this.loading = true;
      this.$socket.emit('notification.list.get', this.options, (resp) => {
        if (resp.result === 'success') {
          this.lists = resp.items;
          this.loading = false;
          if (this.options.page > Math.ceil(this.totalItems / this.options.itemsPerPage)) {
            this.options.page = 1;
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.loading = false;
        }
      });
    },
    getGroupList() {
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    updateNotification(list, read = true) {
      const keys = list.map((r) => r.no);
      this.$socket.emit('notification.read.update', {
        read,
        target: keys,
      }, (resp) => {
        if (resp.result === 'success') {
          this.getSummary();
          if (keys.length >= 1) {
            this.alert(['success', '알림이 읽음처리 되었습니다.']);
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    updateOptions(type, value) {
      let valArr = [];
      valArr = [value];
      this.options[type] = valArr;
    },
    notificationModalInit() {
      this.notificationModal(false);
      setTimeout(() => {
        this.notifications([]);
      }, 500);
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getGroupList();
          this.getSummary();
          this.notificationModalInit([]);
        }
      },
    },
    options: {
      deep: true,
      handler() {
        if (this.userInfo != null && this.totalItems >= 0) {
          this.getSummary();
        }
      },
    },
    dateFilter: {
      deep: true,
      handler(val) {
        if (this.userInfo != null && val.endPicker === false && val.startPicker === false) {
          this.options.filters[0].value = time.makeUTCDate(val.startDate);
          this.options.filters[1].value = time.makeUTCDate(
            `${val.endDate} 23:59:29`,
          );
        }
      },
    },
    radioFilters: {
      deep: true,
      handler(val) {
        if (this.userInfo != null) {
          const value = val.read.value === '전체' ? '' : val.read.value;
          this.options.filters[2].value = value;
        }
      },
    },
    search: {
      handler(val) {
        if (this.userInfo != null) {
          this.options.filters[3].filters[0].value = val;
        }
      },
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
</style>
